import React from "react"
import Helmet from "react-helmet"

import Navbar from "./navbar"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Developer Portal | Liquid Group Pte. Ltd.</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <div id="master-container">{children}</div>
      <Footer />
    </React.Fragment>
  )
}
