import React from "react"

import SiteLayout from "../../components/layout/site"

export default () => {
  return (
    <SiteLayout title="Support">
      <section className="section section-lg">
        <div className="container">
          <h1>Contact Us</h1>
          <p>
            If you have any questions regarding the Liquid Open API Platform,
            please send us an email at{" "}
            <a href="mailto:techsupport@liquidpay.com">
              techsupport@liquidpay.com
            </a>
          </p>
        </div>
      </section>
    </SiteLayout>
  )
}
